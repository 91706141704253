$(function() {
  $('#menu').click(function() {
    if ($('nav').hasClass('open')) {
      $('nav').removeClass('open');
      $('html').css('overflow', 'initial');
      $('body').css('overflow', 'initial');
    } else {
      $('nav').addClass('open');
      $('html').css('overflow', 'hidden');
      $('body').css('overflow', 'hidden');
    }
  });

  $('nav .close').click(function() {
    if ($('nav').hasClass('open')) {
      $('nav').removeClass('open');
      $('html').css('overflow', 'initial');
      $('body').css('overflow', 'initial');
    } else {
      $('nav').addClass('open');
      $('html').css('overflow', 'hidden');
      $('body').css('overflow', 'hidden');
    }
  });
});